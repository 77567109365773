import axios from "@/libs/api.request";

export const base64 = (reqdata) => {
  return axios.request({
    url: "/tool/base64/",
    method: "post",
    data: reqdata,
  });
};

export const url_encode = (reqdata) => {
  return axios.request({
    url: "/tool/url/",
    method: "post",
    data: reqdata,
  });
};

export const md5 = (reqdata) => {
  return axios.request({
    url: "/tool/md5/",
    method: "post",
    data: reqdata,
  });
};

export const hex = (reqdata) => {
  return axios.request({
    url: "/tool/hex/",
    method: "post",
    data: reqdata,
  });
};

export const aes = (reqdata) => {
  return axios.request({
    url: "/tool/aes/",
    method: "post",
    data: reqdata,
  });
};

export const encrypt = (reqdata) => {
  return axios.request({
    url: "/tool/encrypt/",
    method: "post",
    data: reqdata,
  });
};
