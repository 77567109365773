<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <a-menu :selectedKeys="current" @select="changeSelect" mode="horizontal">
      <a-menu-item key="aes"> AES </a-menu-item>
      <a-menu-item key="md5"> MD5 </a-menu-item>
      <a-menu-item key="encrypt"> Encrypt </a-menu-item>
    </a-menu>
    <div style="margin-top: 20px">
      <div v-if="current.indexOf('aes') !== -1">
        <div>
          <a-col :span="12">
            <a-textarea
              v-model="aes_origin"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%"
              placeholder="想要加密/解密的内容粘贴在这里"
              allowClear
            >
            </a-textarea>
          </a-col>
          <a-col :span="12">
            <a-textarea
              v-model="aes_result"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%; margin-left: 1%"
              placeholder="结果"
              allowClear
            >
            </a-textarea>
          </a-col>
        </div>
        <div style="margin-top: 20px">
          <a-form-model
            :model="aes_form"
            ref="aes_ruleForm"
            layout="inline"
            :rules="aes_rules"
          >
            <a-form-model-item label="加密模式" ref="mode" prop="mode">
              <a-select v-model="aes_form.mode" style="width: 80px">
                <a-select-option :value="1">ECB</a-select-option>
                <a-select-option :value="2">CBC</a-select-option>
                <a-select-option :value="6">CTR</a-select-option>
                <a-select-option :value="5">OFB</a-select-option>
                <a-select-option :value="3">CFB</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="填充" ref="padding" prop="padding">
              <a-select v-model="aes_form.padding" style="width: 150px">
                <a-select-option value="nopadding">nopadding</a-select-option>
                <a-select-option value="pkcs5padding"
                  >pkcs5padding</a-select-option
                >
                <a-select-option value="pkcs7padding"
                  >pkcs7padding</a-select-option
                >
                <a-select-option value="zeropadding"
                  >zeropadding</a-select-option
                >
                <a-select-option value="iso10126">iso10126</a-select-option>
                <a-select-option value="ansix923">ansix923</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="密钥模式" ref="key_mode" prop="key_mode">
              <a-select v-model="aes_form.key_mode" style="width: 100px">
                <a-select-option value="none">不加密</a-select-option>
                <a-select-option value="base64">base64</a-select-option>
                <a-select-option value="hex">hex</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="密钥" ref="key" prop="key">
              <a-input v-model="aes_form.key"></a-input>
            </a-form-model-item>
            <a-form-model-item label="偏移量" ref="iv" prop="iv">
              <a-input v-model="aes_form.iv"></a-input>
            </a-form-model-item>
            <a-form-model-item label="输出" ref="echo_mode" prop="echo_mode">
              <a-select v-model="aes_form.echo_mode" style="width: 100px">
                <a-select-option value="base64">base64</a-select-option>
                <a-select-option value="hex">hex</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="字符集" ref="encoding" prop="encoding">
              <a-select v-model="aes_form.encoding" style="width: 100px">
                <a-select-option value="utf-8">utf-8</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-button type="primary" @click="aes_deal('encrypt')"
              >AES加密</a-button
            >
            <a-button
              style="margin-left: 20px"
              type="primary"
              @click="aes_deal('decrypt')"
              >AES解密</a-button
            >
          </a-form-model>
        </div>
      </div>
      <div v-if="current.indexOf('md5') !== -1">
        <div>
          <a-col :span="12">
            <a-textarea
              v-model="md5_origin"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%"
              placeholder="想要加密的内容粘贴在这里"
              allowClear
            >
            </a-textarea>
          </a-col>
          <a-col :span="12">
            <a-textarea
              v-model="md5_result"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%; margin-left: 1%"
              placeholder="结果"
              allowClear
            >
            </a-textarea>
          </a-col>
        </div>
        <div style="margin-top: 20px; float: right">
          <a-select v-model="md5_encoding" style="width: 100px">
            <a-select-option value="utf-8">utf-8</a-select-option>
          </a-select>
          <a-button type="primary" @click="md5_encode" style="margin-left: 10px"
            >加密</a-button
          >
        </div>
      </div>
      <div v-if="current.indexOf('encrypt') !== -1">
        <div>
          <a-col :span="12">
            <a-textarea
              v-model="encrypt_origin"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%"
              placeholder="想要加/解密的内容粘贴在这里"
              allowClear
            >
            </a-textarea>
          </a-col>
          <a-col :span="12">
            <a-textarea
              v-model="encrypt_result"
              :autoSize="{ minRows: 20, maxRows: 20 }"
              style="width: 99%; margin-left: 1%"
              placeholder="结果"
              allowClear
            >
            </a-textarea>
          </a-col>
        </div>
        <div style="margin-top: 20px; float: right">
          <a-form-model
            :model="encrypt_form"
            ref="encrypt_form"
            layout="inline"
          >
            <a-form-model-item
              label="密钥"
              ref="encrypt_key"
              prop="encrypt_key"
            >
              <a-input v-model="encrypt_form.encrypt_key"></a-input>
            </a-form-model-item>
            <a-form-model-item
              label="密钥模式"
              ref="encrypt_mode"
              prop="encrypt_mode"
            >
              <a-select
                v-model="encrypt_form.encrypt_mode"
                style="width: 150px"
              >
                <a-select-option value="none">不加密</a-select-option>
                <a-select-option value="base64">base64</a-select-option>
                <a-select-option value="hex">hex</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              label="类型"
              ref="encrypt_type"
              prop="encrypt_type"
            >
              <a-select
                v-model="encrypt_form.encrypt_type"
                style="width: 150px"
              >
                <a-select-option value="vip_address"
                  >vip_address</a-select-option
                >
                <a-select-option value="vip_address_add"
                  >vip_address_add</a-select-option
                >
                <a-select-option value="shiro">shiro</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-button
              type="primary"
              @click="encrypt_deal('encrypt')"
              style="margin-left: 10px"
              >加密</a-button
            >
            <a-button
              type="primary"
              @click="encrypt_deal('decrypt')"
              style="margin-left: 10px"
            >
              解密
            </a-button>
          </a-form-model>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import { md5, aes, encrypt } from "@/api/tool";
export default {
  data() {
    return {
      current: ["aes"],
      aes_origin: "",
      aes_result: "",
      aes_form: {
        mode: 1,
        padding: "nopadding",
        key_mode: "none",
        key: "",
        iv: "",
        echo_mode: "base64",
        encoding: "utf-8",
      },
      aes_rules: {},
      md5_origin: "",
      md5_result: "",
      md5_encoding: "utf-8",
      encrypt_origin: "",
      encrypt_result: "",
      encrypt_form: {
        encrypt_key: "",
        encrypt_type: "vip_address",
        encrypt_mode: "none",
      },
    };
  },
  methods: {
    changeSelect(item) {
      this.current = item.keyPath;
    },
    encrypt_deal(action) {
      encrypt({
        data: this.encrypt_origin,
        action: action,
        key: this.encrypt_form.encrypt_key,
        mode: this.encrypt_form.encrypt_type,
        key_mode: this.encrypt_form.encrypt_mode,
      })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.error(res.data.msg);
          } else {
            this.encrypt_result = res.data.msg;
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    aes_deal(action) {
      this.$refs["aes_ruleForm"].validate((valid) => {
        if (valid) {
          var data = {
            plaintext: this.aes_origin,
            action: action,
          };
          var reqdata = Object.assign({}, data, this.aes_form);
          aes(reqdata)
            .then((res) => {
              if (res.data.status === 0) {
                this.$message.error(res.data.msg);
              } else {
                this.aes_result = res.data.msg;
              }
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
        }
      });
    },
    md5_encode() {
      md5({ md5_origin: this.md5_origin, md5_encoding: this.md5_encoding })
        .then((res) => {
          if (res.data.status === 0) {
            this.$message.error(res.data.msg);
          } else {
            this.md5_result = res.data.msg;
          }
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
  },
  mounted() {},
};
</script>
